@font-face {
  font-family: 'HD Colton regular';
  src: url(./fonts/hdcolton-regular.otf) format('opentype')
}

@font-face {
  font-family: 'HD Colton semibold';
  src: url(./fonts/hdcolton-semibold.otf) format('opentype');
}

.App {
  max-width: 450px;
  overflow-y: auto;
  text-align: center;
  display: flex;
  flex: 1;
  font-family: 'HD Colton regular';
  flex-direction: column;
  min-height: 926px;
  margin: auto;
  width: 100vw;
}

.header {
  padding-top: 25px;
  margin: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header-title {
  font-size: 20px;
  font-family: 'HD Colton semibold';
  color: #4A3724;
}

.top {
  background-color: white;
  height: 434;
}

.bottom {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #493724;
  height: 492px;
}

.calc {
  background-color: white;
  border-radius: 10px;
  height: 182px;
  align-self: center;
  width: 90vw;
  max-width: 371px;
  padding-top: 20px;
}

.MuiButtonBase-root {
  text-transform: none !important;
  background-color: #F3B610 !important;
  width: 80vw;
  max-width: 350px !important;
  height: 50px !important;
  color: black !important;
  font-family: 'HD Colton semibold' !important;
}

.MuiInputBase-root {
  width: 80vw;
  max-width: 347px;
}

.MuiInputBase-input{
  text-align: right !important;

  font-size: 30px !important;
  font-family: 'HD Colton regular' !important;
}

.MuiFormControl-root {
  margin-top: 30px !important;
}

.MuiTypography-root {
  font-size: 30px !important;
  font-family: 'HD Colton regular' !important;
}